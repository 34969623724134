import React, { useState } from 'react';
import css from './Header.module.css';
import Logo from '../../assets/beautylairlogo.png';
import {CgShoppingBag} from 'react-icons/cg';
import {GoThreeBars} from 'react-icons/go';
import { Link } from 'react-router-dom';

const Header = () => {

  const [ShowMenu, setShowMenu] = useState(true)
  const toggleMenu = () => {
    setShowMenu((ShowMenu)=> !ShowMenu)
  }
  return (
    <div className={css.container}>
        <div className={css.logo}>
            <img src={Logo} alt=''/>
            <span>Beauty Lair & Aesthetic Training Center</span>
        </div>
        <div className={css.right}>
            <div className={css.bars} onClick={toggleMenu}>
                <GoThreeBars/>
            </div> 
                <ul className={css.menu}style={{display: ShowMenu? 'inherit':'none'}}>
                    <li>Collections</li>
                    <li>Services</li>
                    <li>Contact Us</li>
                    <li><Link to="/schedule">Schedule</Link></li>
                </ul>
            <input type='text' className={css.search} placeholder='Search'></input>
            <CgShoppingBag className={css.cart}/>
        </div>
    </div>
  )
}
export default Header