import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import "./App.css";
import Footer from "./components/footer/Footer";
import Header from "./components/header/Header";
import Hero from "./components/hero/Hero";
import Products from "./components/products/Products";
import Slider from "./components/slider/Slider";
import Testimonials from "./components/testimonials/Testimonials";
import Virtual from "./components/virtual/Virtual";
import SchedulePage from "./components/schedule/SchedulePage.jsx";
import Advertisement from "./components/advertisement/Advertisement";

function App() {
  return (
    <Router>
      <div className="App">
        <Header />
        <Routes>
          <Route path="/" element={
            <>
              <Hero />
              <Slider />
              <Virtual />
              <Advertisement/>
              <Products />
              <Testimonials />
            </>
          } />
          <Route path="/schedule" element={<SchedulePage />} />
        </Routes>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
