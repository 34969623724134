import React, {useState} from 'react';
import css from './SchedulePage.module.css';
import {services} from '../../data/services';
import appointmentImage from '../../assets/stylist.png';
import { CLIENT_ID, REDIRECT_URI } from '../../config';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import axios from 'axios';

const SchedulePage = () => {

 const [name, setName] = useState('');
 const [cellphone, setCellphone] = useState('');
 const [email, setEmail] = useState('');
 const [serviceType, setServiceType] = useState('');
 const [selectedDate, setSelectedDate] = useState(new Date());
 const [selectedTime, setSelectedTime] = useState("09:00");

 const handleSubmit = async (e) => {
    e.preventDefault();

    const appointmentDetails = {
        name, cellphone, email, serviceType,
    };
    console.log(appointmentDetails);

 const token = localStorage.getItem('googleAccessToken');
  if (!token) {
      alert('Please connect with Google Calendar first.');
      return;
  }

  console.log("Retrieved Token from localStorage: ", token);

  const eventDateTime = new Date(selectedDate);
  const [hours, minutes] = selectedTime.split(":");
  eventDateTime.setHours(hours);
  eventDateTime.setMinutes(minutes);

  const endDateTime = new Date(eventDateTime);
  endDateTime.setHours(eventDateTime.getHours() + 1); 

    // Create Google Calendar Event here:
    const event = {
      'summary': 'Appointment: ' + serviceType,
      'location': 'Your Business Address',
      'description': `Appointment with ${name}. Contact: ${cellphone}`,
      'start': {
          'dateTime': eventDateTime.toISOString(),
          'timeZone': 'America/Los_Angeles'
      },
      'end': {
          'dateTime': endDateTime.toISOString(),
          'timeZone': 'America/Los_Angeles'
      }
  };
  try {
    await axios.post('https://www.googleapis.com/calendar/v3/calendars/primary/events', event, {
        headers: { 'Authorization': `Bearer ${token}` }
    });
    alert('Appointment scheduled in Google Calendar!');
  } catch (error) {
    console.error("Error adding event to Google Calendar", error);
    if (error?.response?.status === 401) {
        // Handle token refresh logic here, if needed.
    }
  }
}

    // Function to redirect to Google OAuth
    const redirectToGoogle = () => {
      const oauthURL = `https://accounts.google.com/o/oauth2/v2/auth?client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&response_type=code&scope=https://www.googleapis.com/auth/calendar&access_type=offline`;
      window.location.href = oauthURL;
  }


    return (
        <div className={css.container}>
        <form onSubmit={handleSubmit} className={css.form}>
          {services.map(serviceCategory => (
            <div key={serviceCategory.category}>
              <h2>{serviceCategory.category}</h2>
              <p>{serviceCategory.duration}</p>
              <p>{serviceCategory.description}</p>
  
              {serviceCategory.options.map(option => (
                <fieldset key={option.type}>
                  <legend>{option.type}</legend>
                  {option.services.map(service => (
                    <label key={service.name}>
                      <input
                        type="radio"
                        name="service"
                        value={service.name}
                        onChange={(e) => setServiceType(e.target.value)}
                      />
                      {service.name} - {service.duration} @ {service.price}
                    </label>
                  ))}
                </fieldset>
              ))}
            </div>
          ))}  
            <input
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
            <input
              type="tel"
              placeholder="Cellphone Number"
              value={cellphone}
              onChange={(e) => setCellphone(e.target.value)}
              required
            />
            <input
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
             <DatePicker
              selected={selectedDate}
              onChange={date => setSelectedDate(date)}
              dateFormat="yyyy-MM-dd"
            />
            <input 
              type="time" 
              value={selectedTime}
              onChange={(e) => setSelectedTime(e.target.value)}
            />
            <button type="submit">Book Appointment</button>
          </form>

          {/* Preview/Reminder */}
           <div className={css.imageReminderContainer}>
           <h1>"Unlock Beauty – Make Your Appointment Today!"</h1>
           <img src={appointmentImage} alt="Appointment" className={css.appointmentImage}/>
           {serviceType && (
               <div className={css.reminder}>
                   <h2>Reminder</h2>
                   <p><strong>What:</strong> {serviceType}</p>
                   <p><strong>When:</strong> <button onClick={redirectToGoogle}>Connect with Google Calendar</button></p>
                   <p>This is a reminder your appointment for {serviceType} is on Friday, August 11, 2023 9:00am.</p>
               </div>
            )}
            </div>
        </div>
    );
}
export default SchedulePage;