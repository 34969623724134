import React from 'react';
import css from '../advertisement/Advertisement.module.css';
import logo from '../../assets/skinesis.png';

const Advertisement = () => {
  return (
    <div className={css.advertisement}>
      <div className={css.container}>
        <div className={css.textContent}>
          <h2>Discover the Radiant Beauty</h2>
          <p>
            Dive into a world where your skin's vitality takes center stage. 
            At Beauty Lair, we don't just offer treatments; we offer transformations. 
            From the very first touch, experience the difference of our skincare solutions.
          </p>
          <button className={css.discoverButton}>Discover More</button>
        </div>
        <img src={logo} alt="Advertisement Image" className={css.adImage} />
      </div>
    </div>
  )
}

export default Advertisement;
