import profile1 from '../assets/profile1.png'
import profile2 from '../assets/profile2.png'

export const TestimonialsData = [
    {
        image : profile1,
        comment: "Hindi ka matatakot mag try and mag on-the-spot-avail ng mga services nila kasi well-explained ang procedures.. amazing ang result, too.. kaso matakaw lang tlg kme and holiday season kaya ayuuuun. Haha mababait and attentive pa nina ate kaya definitely will recommend beauty lair sa mga ibang friends po.. 🥰🥳😊 dami pa mga promos and bundles, makakaless po tlg. Thank you beauty lair. Babalik-balik kame for sure 🥰",
        name: "NeLlamo Ariane"
    },
    {
        image : profile2,
        comment: "Got my cc glow at beauty lair and it was so nice na kahit may kalusugan ako atleast naalagaan kutis nating mga dadbod.🤣🤣🤣 thank you madam sa experience see you next session and gluta shot",
        name: "Kusinerong Lakwatsero Vlogs"
    },
    {
        image : profile1,
        comment: "I would like to commend team beauty lair zamboanga for not compromising the quality of their services that I availed yesterday. Body scrub + full body massage, acne facial and holywood carbon peel kahit mejo late na kame natapos, close na ang shop pero ang patience nila to attend clients consistent pa din.. Best massage in Zamboanga City!! The best, legit. 💯 😍",
        name: "Shelly Valmonte"
    },
    {
        image : profile1,
        comment: " Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.",
        name: "John Alendon"
    },
    {
        image : profile2,
        comment: "Highly recommended! Mam pao and the staffs are very accomodating and the services are affordable. With matching makukulit na puppies na nakakapag pawala ng stress 😁. Having a lot of acne scars is really a confident killer, so i keep on coming back every month for needling and i could tell that my face is getting better now. Thank you beauty lair",
        name: "Nick Gregas"
    },
    {
        image : profile1,
        comment: "From reddish itchy cheeks due to rosacea now my face is in total radiance! Thanks to Pao and her team for the effort to make us more confident.",
        name: "Erwin Dacpano"
    }
]