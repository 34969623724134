import React from 'react';
import css from './Footer.module.css';
import Logo from '../../assets/beautylairlogo.png';
import {
    InboxIcon,
    PhoneIcon,
    LocationMarkerIcon,
    LoginIcon,
    UsersIcon,
    LinkIcon,
} from "@heroicons/react/outline";


const Footer = () => {
  return (
    <div className={css.cFooterWrapper}>
        <hr/>

        <div className={css.cFooter}>
            <div className={css.logo}>
                <img src={Logo} alt=''/>
                <span>Beauty Lair & Aesthetic Training Center</span>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Contact Us</span>
                    <span className={css.pngLine}>
                    <LocationMarkerIcon className={css.icon}/>
                    <span>Don Alfaro Street, Tetuan , Zamboanga City, Zamboanga City, Philippines, 7000</span>
                    </span>
                    <span className={css.pngLine}>
                        <PhoneIcon className={css.icon}/>
                        <a href='0917 330 4377'>0917 330 4377</a>
                    </span>
                    <span className={css.pngLine}>
                        <InboxIcon className={css.icon}/>
                        <a href='blurry03@gmail.com'>blurry03@gmail.com</a>
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Account</span>
                    <span className={css.pngLine}>
                    <LoginIcon className={css.icon}/>
                    Sign In
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Company</span>
                    <span className={css.pngLine}>
                    <UsersIcon className={css.icon}/>
                    <a href='/about'>
                        <p>About Us</p>
                    </a>
                    </span>
                </div>
            </div>
            <div className={css.block}>
                <div className={css.detail}>
                    <span>Resources</span>
                    <span className={css.pngLine}>
                    <LinkIcon className={css.icon}/>
                    <p>Safety Privacy & Terms</p>
                    </span>
                </div>
            </div>
        </div>
            <div className={css.copyRight}>
                <span>All rights reserved.</span>
                <span>Copyrights 2023 by Mhezel Mohammad</span>
        </div>
    </div>
  );
};

export default Footer