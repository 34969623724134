import React from 'react';
import css from './Hero.module.css';
import HeroImg from '../../assets/hero.png';
import {RiShoppingBagFill} from 'react-icons/ri';
import {BsArrowRight} from 'react-icons/bs';
import {motion} from 'framer-motion';

const Hero = () => {

  const transition = {duration: 6,type: "spring"}
  const transition2 = {duration: 4,type: "spring"}
  return (

    <div className={css.container}>
    {/* Left side (hero image and associated elements) */}
    <div className={css.wrapper}>
        <motion.img 
            transition={transition2}
            initial={{bottom:"-4rem"}}
            whileInView={{bottom:"0rem"}}
            src={HeroImg} alt='' width={600}/>
    </div>
    {/* Right side (your text) */}
    <div className={css.h_sides}>
    <span className={css.text1}>Beauty Lair & Aesthetic Training Center</span>
    <div className={css.text2}>
        <span></span>
        <span>{" "}"Discover the secret to flawless beauty. Elevate your glow, naturally.".</span>
    </div>
    <motion.div
        transition={transition2}
        initial={{right: "4%"}}
        whileInView={{right:"2%"}}
        className={css.cart2}>
        <div className={css.signup}>
            <span>Book Us Now!</span>
            <div>
                <BsArrowRight/>
            </div>
        </div>
    </motion.div>
</div>

    {/* <div className={css.h_sides}>
        <span className={css.text1}>Skin Protection Cream</span>
        <div className={css.text2}>
            <span>Trendy Collections</span>
            <span>
            {" "}
            "Discover the secret to flawless beauty. Elevate your glow, naturally."
            </span>
        </div>
        <motion.div
            transition={transition2}
            initial={{right: "4%"}} 
            whileInView={{right:"2%"}}
            className={css.cart2}>
            <RiShoppingBagFill/>
            <div className={css.signup}>
                <span>Book Us Now!</span>
                <div>
                    <BsArrowRight/>
                </div>
            </div>
        </motion.div>
    </div> */}
</div>



















    // <div className={css.container}>
    //     {/*left side */}
    //     {/* <div className={css.h_sides}>
    //         <span className={css.text1}>Skin Protection Cream</span>
    //         <div className={css.text2}>
    //             <span>Trendy Collections</span>
    //             <span>
    //             {" "}
    //             Lorem ipsum, dolor sit amet consectetur.
    //             </span>
    //         </div>
    //     </div> */}
    //     {/* middle side */}
    //     <div className={css.wrapper}>
    //         <motion.div 
    //         initial={{bottom:"4rem"}}
    //         whileInView={{bottom:"0rem"}}
    //         transition={transition}
    //         className={css.blueCircle}>
    //         </motion.div>

    //         <motion.img 
    //         transition={transition2}
    //         initial={{bottom:"-4rem"}}
    //         whileInView={{bottom:"0rem"}}
    //         src={HeroImg} alt='' width={600}/>

    //         <motion.div
    //         transition={transition2}
    //         initial={{right: "4%"}} 
    //         whileInView={{right:"2%"}}
    //         className={css.cart2}>
    //             <RiShoppingBagFill/>
    //             <div className={css.signup}>
    //                 <span>Best Sign Up Offers</span>
    //                 <div>
    //                 <BsArrowRight/>
    //                 </div>
    //             </div>
    //         </motion.div>
    //     </div>
    //     {/*right side */}
    //      {/* <div className={css.h_sides}>
    //         <div className={css.traffic}>
    //             <span>1.5M</span>
    //             <span>Monthly Traffic</span>
    //         </div>
    //         <div className={css.customers}>
    //             <span>100k</span>
    //             <span>Happy Customers</span>
    //         </div>
    //      </div> */}
    // </div>
  )
}
export default Hero