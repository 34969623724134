export const services = [
    {
      category: "Haircut + Color",
      duration: "2 hours",
      description: "Please specify what type of cut and color service you want",
      options: [
        {
          type: "Color",
          services: [
            {
              name: "Single process",
              duration: "1 hour 30 minutes",
              price: "$110.00"
            },
            {
              name: "Half head highlight",
              duration: "1 hour 30 minutes",
              price: "$120.00"
            },
            {
              name: "Full Head Highlight",
              duration: "1 hour 30 minutes",
              price: "$160.00"
            },
            {
              name: "Balayage",
              duration: "1 hour 30 minutes",
              price: "$180.00"
            },
            {
              name: "Glaze/Toner",
              duration: "1 hour",
              price: "$90.00"
            },
            {
              name: "Color correction",
              duration: "1 hour 30 minutes",
              price: "Price upon consultation"
            },
            {
              name: "Double process",
              duration: "2 hours"
            }
          ]
        },
        {
          type: "Cuts",
          services: [
            {
              name: "Haircut",
              duration: "1 hour",
              price: "$110.00"
            },
            {
              name: "Bang trim",
              duration: "15 minutes",
              price: "$15.00"
            },
            {
              name: "Buzz cut",
              duration: "45 minutes",
              price: "$60.00"
            }
          ]
        },
        {
          type: "Styling",
          services: [
            {
              name: "Blow out",
              duration: "30 minutes",
              price: "$45.00"
            },
            {
              name: "Relaxer",
              duration: "1 hour 30 minutes",
              price: "Price upon consultation"
            }
          ]
        },
        {
          type: "Treatments",
          services: [
            {
              name: "Conditioning treatment",
              duration: "30 minutes",
              price: "$60.00"
            },
            {
              name: "Keratin",
              duration: "1 hour 30 minutes",
              price: "$110.00"
            },
            {
              name: "Scalp",
              duration: "30 minutes",
              price: "$60.00"
            }
          ]
        }
      ]
    }
  ];
  
  export default services;